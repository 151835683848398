@tailwind base;
@tailwind components;
@tailwind utilities;

.link-primary-onhover:hover svg path {
  fill: #f1be6b;
}
.compare-table > div {
  padding: 0 3px;
}
.free-field,
.pro-field {
  font-size: 16px;
  font-weight: 600;
  text-align: center;
}
.pro-field svg,
.free-field svg {
  margin-left: auto;
  margin-right: auto;
}
@media screen and (min-width: 768px) {
  .free-field,
  .pro-field {
    font-size: 21px;
    font-weight: 600;
  }
}
.free-field {
  color: #8c859d;
}
.slick-arrow.slick-next {
  display: none !important;
}
.slick-arrow.slick-prev {
  left: -9px;
  z-index: 1;
}
.slick-active.slick-cloned .slide_item {
  margin-left: 0 !important;
}
.slick-slider.game_carousel .slick-track {
  padding-top: 14px;
}
.slick-slider.game_carousel {
  margin-top: -14px;
}

/* Ionic input select */
ion-select {
	--placeholder-color: #ccc;
	--placeholder-opacity: 0.7;
	border:1px solid #ccc;
	padding:0 15px;
	border-radius: 7px;
	height:45px;
	min-height: 45px;
	font-size:13px;
	width: 100%;
	display:flex;
	justify-content: space-between;
  overflow: hidden;
}
ion-select::part(placeholder),
	ion-select::part(text) {
	flex: 0 0 auto;
}
ion-select::part(text) {
	color: #ccc;
}
ion-select::part(icon) {
	color: #fff;
	position: absolute;
	right:0px;
	opacity: 1;
}

/* Overs Widget */
.overBg {
  background: url('Theme/assets/img/ball.png');
  background-size:cover;
  background-position: center center;
  width: 60px;
  height: 60px;
  line-height: 57px;
  text-align: center;
}

#google_translate_element {
  height:50px;
}
#google_translate_element span {
  display: none;
}
.goog-logo-link {
  display:none !important;
} 
.goog-te-gadget{
  color: transparent !important;
}
.goog-te-gadget select {
  margin:0;
  width: 100%;
  height: 40px;
  line-height: 40px;
  color: #fff !important;
  background:none;
  outline: none;
}

/* Table style */
.table-custom {
  background-color: #272332;
  border-radius: 15px;
  padding: 10px;
  margin-bottom: 20px;
  text-align: center;
  overflow: auto;
}
.table-custom th {
  background-color: #F1BE6B;
  color: #111;
  font-weight: 500;
  font-size: 14px;
  height: 45px;
  padding: 5px 15px;
  line-height: 17px;
}
.table-custom table th:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.table-custom table th:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.table-custom td {
  height: 45px;
  padding: 5px 15px;
  font-size: 14px;
}
.table-custom tr {
  opacity: 0.8;
}
.table-custom tr:hover {
  opacity: 1;
}
.table-custom td:first-child,
.table-custom th:first-child {
  text-align: left;
}
.table-custom td:last-child,
.table-custom th:last-child {
  text-align: right;
}
.table-custom tr:nth-child(even) {
  background-color: #34313e;
}
.table-custom tr:nth-child(even) td:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.table-custom tr:nth-child(even) td:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
@media screen and (max-width: 767px) {
  .table-custom-body {
    overflow-x: scroll;
  }
}
@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}


/* OTP INput */
.otpInput {
  min-width: 40px;
  font-size: 20px;
  font-weight: bold;
  height: 50px;
  line-height: 50px;
  text-align: center;
  border-radius: 10px;
  background-color: #4e4659;
  color: #F1BE6B;
  margin:5px;
}

.grecaptcha-badge { 
  visibility: hidden !important;
}